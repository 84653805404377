import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/pool',
      },
   
    ],
  },
  {
    label: t('Earn'),
    icon: 'FarmIcon',
    items: [
      {
        label: t('Farms'),
        href: '/farms',
      },
      {
        label: t('Pools'),
        href: '/pools',
      },
   
    ],
  },
  
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Telegram'),
        href: 'https://t.me/kittyswap2022',
      },
      {
        label: t('Twitter'),
        href: 'https://twitter.com/kittyswap2022',
      },
      {
        label: t('Discord'),
        href: 'https://discord.gg/265jj8nDF5',
      },
      {
        label: t('Medium'),
        href: 'https://medium.com/@kittyswap2022',
      },
      {
        label: t('WhitePaper'),
        href: 'https://kittyswap.org/wp.pdf',
      },
    ],
  },
]

export default config
